<template>
    <div>
        <div class="modal fade custom-fields show" style="display: block" id="custom-field">
            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeModal">
                    <div class="modal-header">
                        <div class="d-flex">
                            <div>
                                <h4 class="modal-title text-capitalize" style="line-height:1.5;">{{ $t("custom job apply field") }}</h4>
                                <a href="https://easy.jobs/docs/how-to-update-the-custom-fields/" target="_blank" class="form-note">
                                        <span class="custom-link">
                                        {{ $t(`How to customize job field?`) }}
                                        </span></a>
                            </div>
                        </div>

                        <button type="button" class="close" @click="closeModal">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>{{ $t("Mark the information that you want from the applicants") }} ({{ $t("Unmarked option will be invisible to the applicants.") }})</label>
                        </div>
                        <div class="error-message" v-if="errors['apply_rules']">{{ errors['apply_rules'].join(' ') }}</div>

                        <div class="row row-cols-md-3" v-if="applyRules">
                            <div class="col" v-for="tab in applyRules">
                                <div class="custom-field-card">
                                    <div class="custom-field-card__head">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="tab.selectAll" @change="selectAllItems(tab)"/>
                                            <span>{{ $t(tab.name) }}</span>
                                        </label>
                                    </div>
                                    <div class="custom-field-card__body text-break">
                                        <ul v-if="tab.rules.length">
                                            <li v-for="rule in tab.rules">
                                                <label class="checkbox">
                                                    <input type="checkbox" v-model="tab.selected" :key="rule.field" :value="rule.field" @change="checkedAllItems(tab, rule)"/>
                                                    <span>{{ $t(rule.name) }}</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col" v-if="customFields.length">
                                <div class="custom-field-card company-custom-field">
                                    <div class="custom-field-card__head">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="allCustomFieldSelected" @change="selectCustomFields(true)"/>
                                            <span>{{ $t('Custom Fields') }}</span>
                                        </label>
                                    </div>

                                    <!--Custom Fields-->
                                    <div class="custom-field-card__body text-break">
                                        <ul>
                                            <li v-for="field in customFields">
                                                <label class="checkbox">
                                                    <input type="checkbox" v-model="form.custom_fields" :key="field.field_name" :value="field" @change="selectCustomFields()"/>
                                                    <span>{{ field.title }}</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="custom-field-card">
                            <div class="custom-field-card__body">
                                <div class="form-note">
                                    <span class="text-capitalize">{{ $t("tips") }}: </span>{{ $t("Fields of ‘Educational Qualification’ are relevant, field can’t be chosen partially.") }}
                                </div>
                                <div class="form-note">
                                    <span class="text-capitalize">{{ $t("tips") }}: </span>{{ $t("Fields of ‘Employment History’ are relevant, field can’t be chosen partially.") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <button class="button semi-button-info" @click.prevent="closeModal">{{ $t("Back") }}</button>
                        <button class="button" :class="(publishable ? 'success-button' : 'info-button')" @click.prevent="saveApplyRules">Save & {{ this.publishable ? 'Publish' : 'Continue' }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>
<script>
import {getRequiredFieldData} from "../../../app/api/CommonRequest";
import client from "../../../app/api/Client";

export default {
    props: [
        'job',
        'publishable'
    ],
    components: {},
    data() {
        return {
            currentJob: this.job,
            applyRules: null,
            rulesData: [],
            customFields: [],
            form: {
                apply_rules: [],
                custom_fields: [],
            },
            errors: [],
            isLoading: false,
            allCustomFieldSelected: false,
        }
    },
    computed: {
        currentJobId() {
            return this.$route.params.id;
        },
    },
    methods: {
        countSelected() {
            let count = 0;
            this.applyRules.map(tab => {
                count += tab.selected.length;
            });
            return count;
        },
        async saveApplyRules() {
            this.isLoading = true;
            if (this.countSelected() == 0) {
                this.$toast.error(this.$t('Please select some fields.'));
                return false;
            }

            this.form.apply_rules = this.applyRules;
            try {
                let {data} = await client().post(`/job/${this.currentJobId}/required-fields`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$toast.success(data.message);
                    this.closeModal();

                    if (this.publishable) {
                        this.publishJob(this.job);
                    }
                } else {
                    this.$toast.error(data.message);
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = err.response.data.message;
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(err.response.data.message);
                }
            }
            this.isLoading = false;
        },
        closeModal() {
            this.$emit('closeModal');
        },

        async findRequiredFieldData() {
            let {data: {apply_rules, custom_fields, job_custom_fields}} = await getRequiredFieldData(this.currentJobId);
            this.applyRules = apply_rules || [];
            this.customFields = custom_fields || [];
            this.form.custom_fields = job_custom_fields || [];
            this.selectCustomFields();
        },

        selectAllItems(tab, isSelect) {
            tab.selected = [];

            if (isSelect !== undefined) {
                tab.selectAll = isSelect;
            }

            if (tab.selectAll) {
                tab.rules.map((rule) => {
                    tab.selected.push(rule.field);
                });
            }
        },

        checkedAllItems(tab, currentRule) {
            let isSelect = tab.selected.filter(tr => (tr === currentRule.field));

            if (tab.id === "education_qualification" || tab.id === "employment_history") {
                this.selectAllItems(tab, isSelect.length);
            }

            tab.selectAll = (tab.rules.length === tab.selected.length);
        },

        selectCustomFields(selectAll = false) {
            if (selectAll && this.allCustomFieldSelected) {
                this.form.custom_fields = this.customFields;
            } else if (selectAll && !this.allCustomFieldSelected) {
                this.form.custom_fields = [];
            }
            this.allCustomFieldSelected = this.customFields.length === this.form.custom_fields.length;
        }
    },
    mounted() {
        this.findRequiredFieldData();
    }
}
</script>

<style scoped>
.custom-field-card ul {
    list-style: none;
}
</style>